// Basis-Einstellungen

// HTML
// Jumping page on scroll bar appearance fix
html {
  margin-left: calc(100vw - 100%);
}

.custom-control.custom-switch {
  transform: scale(1.4);
}
