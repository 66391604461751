// Partials importieren
@import 'partials/_base';
@import 'partials/_colors';
@import 'partials/_variables';


.react-joyride__tooltip button{
  outline: none !important;
}

html, body{
  background-color: $snow-drift!important;
  min-height: 100%;
}

.btn {
  min-width: 180px!important;
}

#root {
  min-height: 100vh;

  & > .container {
    min-height: 100vh;
  }
}