@import "../../assets/scss/partials/colors";

.Footer {
  background-size: auto;
  background-repeat: no-repeat;
  background-color: $midnight-purple;
  margin-top: auto!important;
  @media (max-width: 959px) {
    padding: 40px 15px 0 15px;

    & > *:nth-child(0) {
      padding-left: 0!important;
    }
  }
}